import { useState } from "react";
import { auth, db } from "../../firebaseConfig.js";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup
} from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import "./login.css";
import googleLogo from '../../images/google.png';

const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  
  const [error, setError] = useState(null);
  const [needsName, setNeedsName] = useState(false); 
  const [tempUser, setTempUser] = useState(null); // Store temporary user info after Google sign in
  const navigate = useNavigate();
  const location = useLocation();

  const handleRegister = async () => {
    try {
      if (!firstName.trim() || !surname.trim()) {
        setError("Please enter both your first name and surname.");
        return;
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const userInfo = {
        firstName,
        surname,
        email,
        role: 'user',
        createdAt: new Date(),
      };

      await setDoc(doc(db, 'users', user.uid), userInfo);

      await signInWithEmailAndPassword(auth, email, password);

      handleRedirectAfterLoginOrSignup();
    } catch (err) {
      switch (err.code) {
        case "auth/email-already-in-use":
          setError("This email is already in use.");
          break;
        case "auth/weak-password":
          setError("Password must be at least 6 characters.");
          break;
        default:
          setError("Registration failed. Please try again.");
      }
    }
  };

  const handleGoogleSignIn = async () => {
    setError(null);
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (!userDocSnap.exists()) {
        // New user, needs first and surname
        setNeedsName(true);
        setTempUser(user);
      } else {
        const userData = userDocSnap.data();
        if (!userData.firstName || !userData.surname) {
          // Missing name info
          setNeedsName(true);
          setTempUser(user);
        } else {
          // Already has name, redirect
          handleRedirectAfterLoginOrSignup();
        }
      }

    } catch (error) {
      console.error('Error signing in with Google:', error);
      setError('Error signing in with Google. Please try again.');
    }
  };

  const handleNameSubmitForGoogleUser = async () => {
    if (!firstName.trim() || !surname.trim()) {
      setError("Please enter both your first name and surname.");
      return;
    }

    try {
      const userDocRef = doc(db, 'users', tempUser.uid);
      await setDoc(userDocRef, {
        firstName,
        surname,
        email: tempUser.email,
        role: 'user',
        createdAt: new Date(),
      }, { merge: true });

      handleRedirectAfterLoginOrSignup();
    } catch (err) {
      console.error('Error updating user info:', err);
      setError('Failed to update user info. Please try again.');
    }
  };

  const handleRedirectAfterLoginOrSignup = () => {
    const { selectedCard, redirectTo } = location.state || {};
    if (selectedCard && redirectTo) {
      navigate(redirectTo, { state: { selectedCard } });
    } else {
      navigate("/sendnuqoot");
    }
  };

  if (needsName && tempUser) {
    // Show a form to collect firstName and surname
    return (
      <div className="login-container">
        <div className="input-container">
          <h2 className="title">Provide Your Name</h2>
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Surname"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
        </div>
        <button className="login-button" onClick={handleNameSubmitForGoogleUser}>
          Continue
        </button>
        {error && <p className="error-text">{error}</p>}
      </div>
    );
  }

  return (
    <div className="login-container">
      <div className="input-container">
        <h2 className="title">Register</h2>
        <input
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Surname"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button className="login-button" onClick={handleRegister}>
        Register
      </button>
      {error && <p className="error-text">{error}</p>}

      <hr className="divider" />

      <button className="social-button google-button" onClick={handleGoogleSignIn}>
        <img src={googleLogo} alt="Google Logo" className="social-logo" />
        Continue with Google
      </button>

      <div className="login-links">
        <p>
          Already have an account?{" "}
          <a href="#/login" className="signup-link">
            Log in
          </a>
        </p>
      </div>
    </div>
  );
};

export default Register;
