import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './navbar.css';
import logo from '../images/logo2.png';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (path, state) => {
    navigate(path, { state });
    setMenuOpen(false);
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <div className="bar">
      <div className="navbar">
        {/* Logo */}
        <div className="logo" onClick={() => handleNavigation('/')}>
          <img src={logo} alt="Logo" className="logo-image" />
        </div>

        {/* Center Links (Desktop) */}
        <div className="links-center">
          <div className="links">
            <p onClick={() => handleNavigation('/')}>Home</p>
            {/* When About is clicked, go to '/' with scrollTo = 'about' */}
            <p onClick={() => handleNavigation('/', { scrollTo: 'about' })}>About</p>

            <div className="dropdown">
              <p className="dropdown-button" onClick={() => handleNavigation('/cards')}>Cards</p>
              <div className="dropdown-menu">
                <div className="dropdown-column">
                  <h4>Special Occasions</h4>
                  <ul>
                    <li onClick={() => handleNavigation('/baby-cards')}>New baby</li>
                    <li onClick={() => handleNavigation('/wedding-cards')}>Wedding</li>
                  </ul>
                </div>
                <div className="dropdown-column">
                  <h4>Holidays</h4>
                  <ul>
                    <li>Coming Soon.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Section (Desktop) */}
        <div className="right-section">
          <button
            className="send-nuqoot-btn"
            onClick={() => handleNavigation('/sendnuqoot')}
          >
            Send Nuqoot
          </button>
        </div>

        {/* Hamburger Menu (Mobile) */}
        <div
          className={`hamburger-icon ${menuOpen ? 'open' : ''}`}
          onClick={toggleMenu}
        >
          {menuOpen ? '✖' : '☰'}
        </div>
      </div>

      {/* Mobile Dropdown */}
      {menuOpen && (
        <div className="mobile-dropdown">
          <p onClick={() => handleNavigation('/')}>Home</p>
          <p onClick={() => handleNavigation('/', { scrollTo: 'about' })}>About</p>
          <p onClick={() => handleNavigation('/cards')}>Cards</p>
          <button
            className="send-nuqoot-btn-mobile"
            onClick={() => handleNavigation('/sendnuqoot')}
          >
            Send Nuqoot
          </button>
        </div>
      )}
    </div>
  );
};

export default Navbar;
