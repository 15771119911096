import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db, auth } from '../../src/firebaseConfig';
import { v4 as uuidv4 } from 'uuid';
import './DeliveryOptions.css';

const DeliveryOptions = () => {
  const [deliveryMethod, setDeliveryMethod] = useState('email');
  const [recipientInfo, setRecipientInfo] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [senderFullName, setSenderFullName] = useState('Someone special');

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCard, message, amount, recipientName } = location.state || {};

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userRef = doc(db, 'users', user.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();
            const firstName = userData.firstName || '';
            const surname = userData.surname || '';
            if (firstName.trim() && surname.trim()) {
              setSenderFullName(`${firstName} ${surname}`);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  if (!selectedCard || !message || !amount || !recipientName) {
    navigate('/sendnuqoot');
    return null;
  }

  const senderEmail = auth.currentUser?.email;

  // 1) Create or retrieve connected account on server for the given recipient’s email
  const createOrRetrieveConnectedAccount = async (recipientEmail) => {
    const apiBaseUrl = 'https://sendnuqoot.com';
    try {
      const response = await fetch(`${apiBaseUrl}/create-or-retrieve-connected-account`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ recipientEmail }),
      });
      if (!response.ok) {
        return null;
      }
      const { accountId } = await response.json();
      return accountId; // The connected account ID for that recipient
    } catch (err) {
      console.error('Error creating/retrieving connected account:', err);
      return null;
    }
  };

  const handleSend = async () => {
    setErrorMessage('');
    try {
      if (!recipientInfo.trim()) {
        setErrorMessage('Please enter the recipient’s contact.');
        return;
      }
      const giftId = uuidv4();

      // Create or get the connected account ID for the recipient
      const accountId = await createOrRetrieveConnectedAccount(recipientInfo);
      if (!accountId) {
        setErrorMessage('Error creating connected account for the recipient.');
        return;
      }

      // 2) Save gift with connectedAccountId
      const giftData = {
        selectedCard,
        message,
        amount: Number(amount),
        senderId: auth.currentUser?.uid,
        senderEmail,
        recipientInfo,     // email or phone
        recipientName,
        deliveryMethod,
        connectedAccountId: accountId, // <--- Store in gift
        status: 'pending',
        createdAt: new Date(),
      };
      await setDoc(doc(db, 'gifts', giftId), giftData);

      const link = `${window.location.origin}/#/gift/${giftId}`;
      const wasSent = await sendLinkToRecipient(recipientInfo, link, deliveryMethod, senderFullName);

      if (!wasSent) {
        setErrorMessage('There was an error sending this gift. No payment was taken. Please contact support.');
        return;
      }

      const transactionId = `G-${giftId.substring(0, 6).toUpperCase()}`;
      const invoiceSent = await sendInvoiceEmail(senderEmail, recipientName, Number(amount), transactionId, giftId);
      if (!invoiceSent) {
        console.error('Failed to send invoice email.');
      }

      navigate('/');
    } catch (error) {
      console.error('Error sending gift:', error);
      setErrorMessage(
        'There was an error in completing this purchase. No payment was taken. Contact support if this persists.'
      );
    }
  };

  const sendLinkToRecipient = async (recipientInfo, link, deliveryMethod, senderFullName) => {
    const apiBaseUrl = 'https://sendnuqoot.com';
    try {
      let response;
      if (deliveryMethod === 'email') {
        response = await fetch(`${apiBaseUrl}/send-email`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ recipientEmail: recipientInfo, link, senderFullName }),
        });
      } else if (deliveryMethod === 'sms') {
        response = await fetch(`${apiBaseUrl}/send-sms`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ recipientPhone: recipientInfo, link }),
        });
      }
      return response && response.ok; 
    } catch (err) {
      console.error('Error sending link to recipient:', err);
      return false;
    }
  };

  const sendInvoiceEmail = async (senderEmail, recipientName, amount, transactionId, giftId) => {
    const apiBaseUrl = 'https://sendnuqoot.com';
    try {
      const response = await fetch(`${apiBaseUrl}/send-invoice-email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ senderEmail, recipientName, amount, transactionId, giftId }),
      });
      return response && response.ok;
    } catch (err) {
      console.error('Error sending invoice email:', err);
      return false;
    }
  };

  return (
    <div className="delivery-options-container">
      <h1 className="title">Choose Delivery Method</h1>
      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <div className="form-grid">
        <select
          className="delivery-select"
          value={deliveryMethod}
          onChange={(e) => setDeliveryMethod(e.target.value)}
        >
          <option value="email">Send via Email</option>
          <option value="sms">Send via SMS</option>
        </select>

        <input
          className="recipient-input"
          type="text"
          placeholder={
            deliveryMethod === 'email' ? 'Recipient Email' : 'Recipient Phone Number'
          }
          value={recipientInfo}
          onChange={(e) => setRecipientInfo(e.target.value)}
        />

        <button className="send-button" onClick={handleSend}>
          Send
        </button>
      </div>
    </div>
  );
};

export default DeliveryOptions;
