import React from 'react';
import './GiftFeatures.css';
import secureImage from '../../images/tick.png'; // Replace with actual image name
import cardImage from '../../images/cards.png'; // Replace with actual image name
import giftImage from '../../images/gift.png'; // Replace with actual image name

const GiftFeatures = () => {
  const features = [
    {
      image: secureImage,
      title: 'Safe and secure',
      description: 'Nuqoot keeps your information secure with advanced encryption, and every gift you send is protected in a fully insured account.',
    },
    {
      image: cardImage,
      title: 'No hidden fees',
      description: 'Sending is simple. We charge a flat 3% fee for all transactions. Your recipient always gets the full gift amount.',
    },
    {
      image: giftImage,
      title: 'Free to receive',
      description: 'Got a cash gift on Nuqoot? Transfer it to your bank anytime—no strings attached!',
    },
  ];

  return (
    <div className="features-container">
      <h1 className="features-title123">SEND GIFTS WITH NO HASSLE</h1>
      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-item">
            <img src={feature.image} alt={feature.title} className="feature-icon" />
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GiftFeatures;
