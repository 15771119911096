import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './Payment.css'; 

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentForm = () => {
  const [amount, setAmount] = useState('');
  const [flipped, setFlipped] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCard, message, recipientName } = location.state || {};

  const stripe = useStripe();
  const elements = useElements();

  if (!selectedCard || !message || !recipientName) {
    navigate('/sendnuqoot');
    return null;
  }

  const handlePayment = async () => {
    if (!amount || isNaN(amount) || Number(amount) <= 0) {
      alert('Please enter a valid amount.');
      return;
    }

    // For demonstration, assume payment is successful
    navigate('/delivery-options', { state: { selectedCard, message, amount, recipientName } });
  };

  const handleCardClick = () => {
    setFlipped(!flipped);
  };

  const isMobile = window.innerWidth < 600;
  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontSize: isMobile ? '12px' : '16px',
        '::placeholder': {
          color: '#aab7c4',
          height: isMobile ? '20px' : '30px',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const giftAmount = parseFloat(amount) || 0;
  const processingFee = giftAmount * 0.03; // 3% fee
  const total = giftAmount > 0 ? giftAmount + processingFee : 0;

  return (
    <div className="payment-container3">
      <h1 className="title3">Attach a Cash Gift to Your Card</h1>
      <p className="flip-instruction3">Click the card to flip and view the personalized message</p>

      <div className="card-flip-container3" onClick={handleCardClick}>
        <div className={`card-flip3 ${flipped ? 'flipped' : ''}`}>
          <div className="card-front3">
            <div className="card-size-wrapper3">
              <img src={selectedCard.imageUrl} alt={selectedCard.name || 'Card'} className="card-image12343" />
              {selectedCard.name && <p className="card-name3">{selectedCard.name}</p>}
            </div>
          </div>
          <div className="card-back3">
            <img src={selectedCard.blankImageUrl} alt="Blank Card" className="card-image12343" />
            <div className="message-overlay3">
              {message}
            </div>
          </div>
        </div>
      </div>

      <div className="fees-summary-container3">
        <h2 className="fees-summary-title3">Review Your Payment</h2>
        <div className="fees-line-item3">
          <span>Gift Amount:</span>
          <span>${giftAmount.toFixed(2)}</span>
        </div>
        <div className="fees-line-item3">
          <span>Processing Fee (3%):</span>
          <span>${processingFee.toFixed(2)}</span>
        </div>
        <hr className="fees-divider3" />
        <div className="fees-line-item3 total-line3">
          <span><strong>Total:</strong></span>
          <span><strong>${total.toFixed(2)}</strong></span>
        </div>
      </div>

      <div className="amount-input-container3">
        <span className="amount-currency3">$</span>
        <input
          type="number"
          placeholder="Enter amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="amount-input3"
        />
      </div>
      <p className="fee-explanation3">
        A 3% processing fee will be added to the gift amount above.
      </p>

      <div className="card-element-container3">
        <CardElement options={cardStyle} />
      </div>
      <button onClick={handlePayment} className="pay-button3">
        Pay and Continue
      </button>
    </div>
  );
};

const Payment = () => (
  <Elements stripe={stripePromise}>
    <PaymentForm />
  </Elements>
);

export default Payment;
