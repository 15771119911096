import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CardsPreview.css'; 
import { auth } from '../../src/firebaseConfig'; // Ensure correct path

// Existing wedding cards (1-9)
import card3 from '../images/12.png';
import card4 from '../images/9.png';
import card5 from '../images/1.png';
import card6 from '../images/3.png';
import card7 from '../images/5.png';
import card8 from '../images/7.png';
import card9 from '../images/11.png';

// Blanks (1-9)
import card3_blank from '../images/12_blank.png';
import card4_blank from '../images/9_blank.png';
import card5_blank from '../images/1_blank.png';
import card6_blank from '../images/3_blank.png';
import card7_blank from '../images/5_blank.png';
import card8_blank from '../images/7_blank.png';
import card9_blank from '../images/11_blank.png';

// Wedding cards (10-17)
import card10 from '../images/13.png';
import card11 from '../images/14.png';
import card12 from '../images/15.png';
import card13 from '../images/16.png';
import card14 from '../images/17.png';
import card15 from '../images/18.png';
import card16 from '../images/19.png';
import card17 from '../images/20.png';

// Blanks (10-17)
import card10_blank from '../images/13_blank.png';
import card11_blank from '../images/14_blank.png';
import card12_blank from '../images/15_blank.png';
import card13_blank from '../images/16_blank.png';
import card14_blank from '../images/17_blank.png';
import card15_blank from '../images/18_blank.png';
import card16_blank from '../images/19_blank.png';
import card17_blank from '../images/20_blank.png';

// NEW wedding cards (21-28)
import card21 from '../images/21.png';
import card22 from '../images/22.png';
import card23 from '../images/23.png';
import card24 from '../images/24.png';
import card25 from '../images/25.png';
import card26 from '../images/26.png';
import card27 from '../images/27.png';
import card28 from '../images/28.png';

// NEW blanks (21-28)
import card21_blank from '../images/21_blank.png';
import card22_blank from '../images/22_blank.png';
import card23_blank from '../images/23_blank.png';
import card24_blank from '../images/24_blank.png';
import card25_blank from '../images/25_blank.png';
import card26_blank from '../images/26_blank.png';
import card27_blank from '../images/27_blank.png';
import card28_blank from '../images/28_blank.png';

const WeddingCardsPreview = () => {
  const navigate = useNavigate();

  const weddingCards = [
    // Existing wedding cards 1-9
    { id: 'card3', imageUrl: card3, blankImageUrl: card3_blank },
    { id: 'card4', imageUrl: card4, blankImageUrl: card4_blank },
    { id: 'card5', imageUrl: card5, blankImageUrl: card5_blank },
    { id: 'card6', imageUrl: card6, blankImageUrl: card6_blank },
    { id: 'card7', imageUrl: card7, blankImageUrl: card7_blank },
    { id: 'card8', imageUrl: card8, blankImageUrl: card8_blank },
    { id: 'card9', imageUrl: card9, blankImageUrl: card9_blank },

    // Wedding cards (10-17)
    { id: 'card10', imageUrl: card10, blankImageUrl: card10_blank },
    { id: 'card11', imageUrl: card11, blankImageUrl: card11_blank },
    { id: 'card12', imageUrl: card12, blankImageUrl: card12_blank },
    { id: 'card13', imageUrl: card13, blankImageUrl: card13_blank },
    { id: 'card14', imageUrl: card14, blankImageUrl: card14_blank },
    { id: 'card15', imageUrl: card15, blankImageUrl: card15_blank },
    { id: 'card16', imageUrl: card16, blankImageUrl: card16_blank },
    { id: 'card17', imageUrl: card17, blankImageUrl: card17_blank },

    // NEW wedding cards (21-28)
    { id: 'card21', imageUrl: card21, blankImageUrl: card21_blank },
    { id: 'card22', imageUrl: card22, blankImageUrl: card22_blank },
    { id: 'card23', imageUrl: card23, blankImageUrl: card23_blank },
    { id: 'card24', imageUrl: card24, blankImageUrl: card24_blank },
    { id: 'card25', imageUrl: card25, blankImageUrl: card25_blank },
    { id: 'card26', imageUrl: card26, blankImageUrl: card26_blank },
    { id: 'card27', imageUrl: card27, blankImageUrl: card27_blank },
    { id: 'card28', imageUrl: card28, blankImageUrl: card28_blank },
  ];

  const handleCardClick = (card) => {
    if (!auth.currentUser) {
      navigate('/login', { state: { selectedCard: card, redirectTo: '/customize-card' } });
    } else {
      navigate('/customize-card', { state: { selectedCard: card } });
    }
  };

  return (
    <div className="send-nuqoot-container1">
      <h1 className="title1">Wedding Cards</h1>
      <p className="preview-subtitle1">Click a card to send nuqoot.</p>

      <div className="card-grid1">
        {weddingCards.map((card) => (
          <div
            key={card.id}
            className="card-item1"
            onClick={() => handleCardClick(card)}
            style={{ cursor: 'pointer' }}
          >
            <img src={card.imageUrl} alt={card.id} className="card-image1" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeddingCardsPreview;
