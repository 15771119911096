import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, db } from "../../firebaseConfig.js";
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import "./login.css";
import googleLogo from '../../images/google.png';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  // For Google sign-in missing names
  const [needsName, setNeedsName] = useState(false); 
  const [tempUser, setTempUser] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      handleRedirectAfterLogin();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async () => {
    setError(null); // Clear any old error before attempting sign in
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        // No user doc yet -> prompt user for first and surname
        setNeedsName(true);
        setTempUser(user);
      } else {
        const userData = userDocSnap.data();
        if (!userData.firstName || !userData.surname) {
          // Missing name info -> prompt user
          setNeedsName(true);
          setTempUser(user);
        } else {
          // User doc has name -> proceed
          handleRedirectAfterLogin();
        }
      }

    } catch (googleError) {
      console.error('Error signing in with Google:', googleError);
      setError('Error signing in with Google. Please try again.');
    }
  };

  const handleNameSubmitForGoogleUser = async () => {
    if (!firstName.trim() || !surname.trim()) {
      setError("Please enter both your first name and surname.");
      return;
    }

    try {
      const userDocRef = doc(db, 'users', tempUser.uid);
      await setDoc(
        userDocRef,
        {
          firstName,
          surname,
          email: tempUser.email,
          role: 'user',
          createdAt: new Date(),
        },
        { merge: true }
      );

      handleRedirectAfterLogin();
    } catch (err) {
      console.error('Error updating user info:', err);
      setError('Failed to update user info. Please try again.');
    }
  };

  const handleRedirectAfterLogin = () => {
    const { selectedCard, redirectTo } = location.state || {};
    if (selectedCard && redirectTo) {
      navigate(redirectTo, { state: { selectedCard } });
    } else {
      navigate("/sendnuqoot");
    }
  };

  // If we still need user to provide name, show that form
  if (needsName && tempUser) {
    return (
      <div className="login-container">
        <div className="input-container">
          <div className="title">Provide Your Name</div>
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Surname"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
        </div>

        <button className="login-button" onClick={handleNameSubmitForGoogleUser}>
          Continue
        </button>
        {/* 
          IMPORTANT:
          We do NOT show {error && <p className="error-text">{error}</p>} here
          because we want a fresh form. We only show the error
          if name submission fails, e.g. "Please enter both your first name..."
        */}
        {error && <p className="error-text">{error}</p>}
      </div>
    );
  }

  // Otherwise, show normal login form
  return (
    <div className="login-container">
      <div className="input-container">
        <div className="title">Login</div>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <button className="login-button" onClick={handleLogin}>
        Continue
      </button>

      <hr className="divider" />

      <button className="social-button google-button" onClick={handleGoogleSignIn}>
        <img src={googleLogo} alt="Google Logo" className="social-logo" />
        Continue with Google
      </button>

      <div className="login-links">
        <a href="#/forgot-password" className="forgot-password-link">
          Forgot password?
        </a>
        <p>
          Don’t have an account?{" "}
          <a href="#/signup" className="signup-link">
            Sign up
          </a>
        </p>
      </div>

      {/* Show errors only for the standard login or Google sign-in errors */}
      {error && <p className="error-text">{error}</p>}
    </div>
  );
};

export default Login;
