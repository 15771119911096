import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom'; 
import NavBar from './components/navbar';
import Home from './pages/Home';
import Signup from './components/auth/signup';
import Login from './components/auth/signin';
import Cards from './pages/Cards';
import SendNuqoot from './components/SendNuqoot';
import CustomizeCard from './components/CustomizeCard';
import Payment from './components/Payment';
import DeliveryOptions from './components/DeliveryOptions';
import ShareLink from './components/ShareLink';
import RecipientView from './components/RecipientView';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AcceptMoney from './components/AcceptMoney';
import ProtectedRoute from './components/auth/ProtectedRoute'; 
import CardsPreview from './components/CardsPreview';
import BabyCardsPreview from './components/BabyCardsPreview';
import WeddingCardsPreview from './components/WeddingCardsPreview';
import SenderView from './components/SenderView';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/baby-cards" element={<BabyCardsPreview />} />
        <Route path="/wedding-cards" element={<WeddingCardsPreview />} />
        <Route path="/cards" element={<CardsPreview />} />
        <Route 
          path="/sendnuqoot" 
          element={

              <SendNuqoot />

          } 
        />
        <Route path="/customize-card" element={            <ProtectedRoute><CustomizeCard />            </ProtectedRoute>} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/delivery-options" element={<DeliveryOptions />} />
        <Route path="/share-link" element={<ShareLink />} />
        <Route path="/gift/:id" element={<RecipientView />} />
        <Route
          path="/accept-money/:id"
          element={
            <Elements stripe={stripePromise}>
              <AcceptMoney />
            </Elements>
          }
        />
        <Route path="/gift-sender/:id" element={<SenderView />} />
      </Routes>
    </Router>
  );
}

export default App;
