import React, { useState, useEffect } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../src/firebaseConfig';
import { useParams } from 'react-router-dom';
import './AcceptMoney.css';

const AcceptMoney = () => {
  const { id: giftId } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [alreadyAccepted, setAlreadyAccepted] = useState(false);

  const [routingNumber, setRoutingNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');

  const [connectedAccountId, setConnectedAccountId] = useState(null);

  const apiBaseUrl = 'https://sendnuqoot.com';

  useEffect(() => {
    const checkGiftStatus = async () => {
      try {
        const giftRef = doc(db, 'gifts', giftId);
        const giftSnap = await getDoc(giftRef);

        if (!giftSnap.exists()) {
          setError('Gift not found.');
          return;
        }

        const giftData = giftSnap.data();
        if (giftData.status === 'accepted') {
          setError('This gift has already been accepted.');
          setAlreadyAccepted(true);
        } else {
          // Retrieve the connectedAccountId from the gift doc
          if (giftData.connectedAccountId) {
            setConnectedAccountId(giftData.connectedAccountId);
          } else {
            setError('No connected account for this gift. Please contact support.');
          }
        }
      } catch (err) {
        console.error('Error checking gift status:', err);
        setError('Error checking gift status. Please try again.');
      }
    };

    checkGiftStatus();
  }, [giftId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!stripe) {
      setError('Stripe has not fully loaded.');
      return;
    }
    if (!connectedAccountId) {
      setError('No connected account found for this gift.');
      return;
    }
    if (!routingNumber.trim() || !accountNumber.trim() || !accountHolderName.trim()) {
      setError('Please enter all bank details (Routing, Account, Name).');
      return;
    }

    setLoading(true);

    // Create bank_account token
    const bankAccountParams = {
      country: 'US',
      currency: 'usd',
      routing_number: routingNumber,
      account_number: accountNumber,
      account_holder_name: accountHolderName,
      account_holder_type: 'individual', 
    };
    const { token, error: stripeError } = await stripe.createToken('bank_account', bankAccountParams);
    if (stripeError) {
      setError(stripeError.message);
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${apiBaseUrl}/payout`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          token: token.id, 
          giftId,
          connectedAccountId  // <--- Send to server
        }),
      });

      const result = await response.json();
      if (response.ok) {
        // Mark gift as accepted
        const giftRef = doc(db, 'gifts', giftId);
        await updateDoc(giftRef, { status: 'accepted', acceptedAt: new Date() });
        setSuccess(true);
      } else {
        setError(result.error || 'Failed to process the payout.');
      }
    } catch (serverError) {
      console.error('Server error:', serverError);
      setError('Server error. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="accept-money-container">
      <h1 className="accept-money-title">Accept Your Gift</h1>
      {alreadyAccepted ? (
        <p className="error-message">{error}</p>
      ) : !success ? (
        <>
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleSubmit} className="accept-money-form">
            <div className="bank-details-container">
              <input
                type="text"
                placeholder="Routing Number (9 digits)"
                value={routingNumber}
                onChange={(e) => setRoutingNumber(e.target.value)}
                className="bank-input"
              />
              <input
                type="text"
                placeholder="Account Number"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
                className="bank-input"
              />
              <input
                type="text"
                placeholder="Account Holder Name"
                value={accountHolderName}
                onChange={(e) => setAccountHolderName(e.target.value)}
                className="bank-input"
              />
            </div>
            <button
              type="submit"
              disabled={!stripe || loading}
              className="accept-money-button"
            >
              {loading ? 'Processing...' : 'Accept Funds'}
            </button>
          </form>
        </>
      ) : (
        <p className="success-message">
          Your funds are on the way! Thank you for accepting the gift.
        </p>
      )}
    </div>
  );
};

export default AcceptMoney;
