import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './CustomizeCard.css';

const CustomizeCard = () => {
  const [message, setMessage] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false); // For mobile flipping

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCard } = location.state || {};

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 890);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!selectedCard) {
    navigate('/sendnuqoot');
    return null;
  }

  const handleNext = () => {
    if (!message.trim()) {
      alert('Please enter a message for your card.');
      return;
    }
    if (!recipientName.trim()) {
      alert('Please enter the recipient\'s name.');
      return;
    }
    navigate('/payment', { state: { selectedCard, message, recipientName } });
  };

  const handleCardClick = () => {
    if (isMobile) {
      setIsFlipped(!isFlipped);
    }
  };

  return (
    <div className="customize-card-container1">
      <h1 className="title1">Write your personal message</h1>

      {isMobile ? (
        <div className="card-preview-mobile1" onClick={handleCardClick}>
          <div className="card-image-container1">
            <img
              src={isFlipped ? selectedCard.imageUrl : selectedCard.blankImageUrl}
              alt="Card"
              className="card-image121"
            />
            {!isFlipped && (
              <div className="message-overlay1">
                {message}
              </div>
            )}
          </div>
          <p className="flip-instruction1">
            {isFlipped ? 'Tap to see the back again' : 'Tap to see the front'}
          </p>
        </div>
      ) : (
        <div className="card-preview-desktop1">
          <div className="card-image-container1">
            <img
              src={selectedCard.imageUrl}
              alt="Front Card"
              className="card-image121"
            />
          </div>
          <div className="card-image-container1">
            <img
              src={selectedCard.blankImageUrl}
              alt="Back Card"
              className="card-image121"
            />
            <div className="message-overlay1">
              {message}
            </div>
          </div>
        </div>
      )}

      <div className="customizedcontainer1">
        <textarea
          className="message-input121"
          placeholder="Enter your special message here..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <input
          type="text"
          placeholder="Recipient's Full Name"
          value={recipientName}
          onChange={(e) => setRecipientName(e.target.value)}
          className="recipient-name-input"
        />
      </div>
      
      <button className="next-button1" onClick={handleNext}>
        Next
      </button>
    </div>
  );
};

export default CustomizeCard;
