import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import './RecipientView.css';

const RecipientView = () => {
  const { id } = useParams();
  const [gift, setGift] = useState(null);
  const [flipped, setFlipped] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGift = async () => {
      try {
        const docRef = doc(db, 'gifts', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setGift(docSnap.data());
        } else {
          console.error('Gift not found for ID:', id);
          navigate('/');
        }
      } catch (error) {
        console.error('Error fetching gift:', error);
        navigate('/');
      }
    };

    fetchGift();
  }, [id, navigate]);

  useEffect(() => {
    if (gift) {
      // Automatically flip after 2 seconds
      const flipTimer = setTimeout(() => {
        setFlipped(true);
      }, 2000);
      return () => clearTimeout(flipTimer);
    }
  }, [gift]);

  if (!gift) {
    return <p>Loading...</p>;
  }

  const handleAccept = () => {
    navigate(`/accept-money/${id}`);
  };

  const handleCardClick = () => {
    // Toggle the flipped state when card is clicked
    setFlipped(!flipped);
  };

  const handleReplayAnimation = () => {
    // Reset card to front
    setFlipped(false);
    // Re-flip after 2 seconds to replay the animation
    setTimeout(() => {
      setFlipped(true);
    }, 2000);
  };

  return (
    <div className="recipient-view-container">
      <h1 className="title">You've Received a Gift!</h1>
      <div className="card-flip-container" onClick={handleCardClick}>
        <div className={`card-flip ${flipped ? 'flipped' : ''}`}>
          <div className="card-front">
            <div className="card-size-wrapper">
              <img src={gift.selectedCard?.imageUrl} alt="Gift Card" className="card-image1234" />
            </div>
          </div>
          <div className="card-back">
            <img src={gift.selectedCard?.blankImageUrl} alt="Blank Card" className="card-image1234" />
            <div className="message-overlay">
              <div className="message-text">{gift.message}</div>
            </div>
          </div>
        </div>
      </div>
      {flipped && (
        <>
          <button className="accept-button" onClick={handleAccept}>
            Accept Your Nuqoot
          </button>
          <button className="replay-button" onClick={handleReplayAnimation}>
            Replay Animation
          </button>
        </>
      )}
    </div>
  );
};

export default RecipientView;
