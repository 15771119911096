import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CardsPreview.css'; 
import { auth } from '../../src/firebaseConfig'; // Ensure correct path

// Existing Imports
import bbcard1 from '../images/baby1.png';
import bbcard2 from '../images/baby3.png';
import bbcard3 from '../images/baby5.png';
import bbcard4 from '../images/baby7.png';

import bbcard1_blank from '../images/baby1_blank.png';
import bbcard2_blank from '../images/baby3_blank.png';
import bbcard3_blank from '../images/baby5_blank.png';
import bbcard4_blank from '../images/baby7_blank.png';

// New Card Imports
import bbcard5 from '../images/baby8.png';
import bbcard6 from '../images/baby9.png';
import bbcard7 from '../images/baby10.png';
import bbcard8 from '../images/baby11.png';

import bbcard5_blank from '../images/baby8_blank.png';
import bbcard6_blank from '../images/baby9_blank.png';
import bbcard7_blank from '../images/baby10_blank.png';
import bbcard8_blank from '../images/baby11_blank.png';

const BabyCardsPreview = () => {
  const navigate = useNavigate();

  // Include new cards in the array
  const babyCards = [
    { id: 'bbcard1', imageUrl: bbcard1, blankImageUrl: bbcard1_blank },
    { id: 'bbcard2', imageUrl: bbcard2, blankImageUrl: bbcard2_blank },
    { id: 'bbcard3', imageUrl: bbcard3, blankImageUrl: bbcard3_blank },
    { id: 'bbcard4', imageUrl: bbcard4, blankImageUrl: bbcard4_blank },

    // New cards added here
    { id: 'bbcard5', imageUrl: bbcard5, blankImageUrl: bbcard5_blank },
    { id: 'bbcard6', imageUrl: bbcard6, blankImageUrl: bbcard6_blank },
    { id: 'bbcard7', imageUrl: bbcard7, blankImageUrl: bbcard7_blank },
    { id: 'bbcard8', imageUrl: bbcard8, blankImageUrl: bbcard8_blank },
  ];

  const handleCardClick = (card) => {
    if (!auth.currentUser) {
      navigate('/login', { state: { selectedCard: card, redirectTo: '/customize-card' } });
    } else {
      navigate('/customize-card', { state: { selectedCard: card } });
    }
  };

  return (
    <div className="send-nuqoot-container1">
      <h1 className="title1">New Baby Cards</h1>
      <p className="preview-subtitle1">Click a card to send nuqoot.</p>

      <div className="card-grid1">
        {babyCards.map((card) => (
          <div
            key={card.id}
            className="card-item1"
            onClick={() => handleCardClick(card)}
            style={{ cursor: 'pointer' }}
          >
            <img src={card.imageUrl} alt={card.id} className="card-image1" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BabyCardsPreview;
